import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ButtonColor, ButtonStyle, IAction, IViewMode, ThresholdValue } from '@shared/classes';
import { min } from 'lodash-es';

@Component({
  selector: 'app-threshold-item',
  templateUrl: './threshold-item.component.html',
  styleUrls: ['./threshold-item.component.scss'],
})
export class ThresholdItemComponent implements OnInit, OnChanges {
  @Input() threshold: ThresholdValue;
  @Input() floor: any;
  @Input() ceil: any;
  @Input() last: boolean = false;
  @Input() disabled: boolean;
  @Input() viewMode: IViewMode;
  @Output('onRemoveThresholdItem') onRemoveThresholdItem: EventEmitter<any> = new EventEmitter();

  tableActions: IAction[] = [
    {
      id: 2,
      icon: 'pi pi-trash',
      buttonStyle: ButtonStyle.Text,
      buttonClass: 'w-full',
      command: this.onRemove.bind(this),
      color: ButtonColor.Danger,
      passEvent: true,
    },
  ];

  formGroup: FormGroup;

  value: number = 50;
  options = {
    floor: 0,
    ceil: 100,
    disabled: false,
  };
  constructor() {
    this.formGroup = new FormGroup({
      value: new FormControl(null),
      name: new FormControl(),
      color: new FormControl(),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    //Couldn't use the supplied onChange events, they dont refresh the rest of the items,even with manual refresh
    // todo: check how to properly remove the ngOnChanges for better optimization
    this.setNewOptions(min([this.floor, this.ceil]), this.ceil, this.disabled);

    if (this.threshold?.value < this.options?.floor) {
      this.threshold.value = this.options?.floor;
    }

    if (!this.disabled && this.threshold?.value > this.options?.floor) {
      let oldValue = this.threshold?.value;

      this.threshold.value = this.threshold.value - 1;
      setTimeout(() => {
        this.threshold.value = this.threshold?.value > oldValue ? this.threshold?.value : oldValue;
      }, 1);
    }

    if (!this.disabled && this.threshold?.value > this.options?.ceil) {
      this.threshold.value = this.options?.ceil;
    }
    if (this.disabled) {
      let oldValue = this.threshold?.value;

      this.threshold.value = this.threshold.value + 1;
      setTimeout(() => {
        this.threshold.value = this.options?.ceil;
      }, 1);
    }
  }

  ngOnInit(): void {
    this.options.floor = min([this.floor, this.ceil]);
    this.options.ceil = this.ceil;
    this.options.disabled = this.disabled;

    this.formGroup.patchValue({
      ...this.threshold,
    });
    setTimeout(() => {
      this.threshold.value = this.threshold.value;
    }, 1000);
  }
  onRemove() {
    this.onRemoveThresholdItem.emit(this.threshold);
  }

  setNewOptions(newFloor: number, newCeil: number, newDisabled: boolean): void {
    // Due to change detection rules in Angular, we need to re-create the options object to apply the change
    const newOptions = Object.assign({}, this.options);
    newOptions.floor = newFloor;
    newOptions.ceil = newCeil;
    newOptions.disabled = newDisabled;
    this.options = newOptions;
  }
}
