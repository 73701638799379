import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IAction,
  IViewMode,
  ImpactValue,
  ItemBasePage,
  LikelihoodValue,
  ModuleKeywords,
  ModuleRoutePrefix,
  RiskMethodology,
  TargetTypeEnum,
  ThresholdValue,
  toKebabCase,
} from '@shared/classes';
import { AppDialogService, IConfirmationConfig, IConfirmationPosition } from '@shared/services/app-dialog.service';
import { ToastService } from '@shared/services/toast.service';
import { cloneDeep, maxBy, minBy } from 'lodash-es';
import { RiskMethodologyDataService } from '../../../services/data/risk-methodology-data.service';
import { SummaryPopupComponent } from '../summary-popup/summary-popup.component';

@Component({
  selector: 'app-RiskMethodology',
  templateUrl: './RiskMethodology.component.html',
  styleUrl: './RiskMethodology.component.scss',
})
export class RiskMethodologyComponent extends ItemBasePage<RiskMethodology> implements OnInit {
  @Input() showSummaryOnly: boolean = false;
  activeMethodology: RiskMethodology;

  public activeMeth: RiskMethodology;
  public impactList: ImpactValue[];

  public likelihoodList: LikelihoodValue[];
  public thresholdList: ThresholdValue[];

  public minThreshold;
  public maxThreshold;

  acceptableRiskValue = 50;

  editRM = false;
  newRM = false;

  options: any = {
    floor: 0,
    ceil: 100,
  };

  stateExist: boolean;

  selectedRm: RiskMethodology;
  selectedRmName: string;
  selectedRmDescription: string;

  submitButtonAction: IAction = {
    id: 1,
    label: 'Edit Risk Methodology',
    command: this.onEdit.bind(this),
    buttonType: 'button',
    buttonClass: 'w-full',
    passEvent: true,
  };

  constructor(
    private route: ActivatedRoute,
    private riskMethodologyDataService: RiskMethodologyDataService,
    public appDialogService: AppDialogService,
    private router: Router,
    private toastService: ToastService
  ) {
    super(
      { moduleKeyword: ModuleKeywords.RiskMethodology, routePrefix: ModuleRoutePrefix.Risk },
      router,
      riskMethodologyDataService,
      toastService
    );
    this.subs.sink = this.route.params.subscribe({
      next: (params) => {
        this.itemId = params['id'];
        this.SetPageValues({
          breadCrumb: {
            items: [
              {
                label: 'Risk',
                routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
              },
              {
                label: 'Risk Methodologies',
                routerLink: [`/${this.routePrefix}/${toKebabCase(this.moduleKeyword)}/list`],
              },
              {
                label: 'Active',
                routerLink: [],
              },
            ],
          },
          itemId: this.itemId,
          fromType: TargetTypeEnum.RiskMethodology,
        });
      },
    });
  }

  ngOnInit() {
    this.riskMethodologyDataService.fetchActiveRiskMethodology().subscribe({
      next: (res) => {
        this.activeMethodology = res?.data;
        this.refreshSelected(this.activeMethodology);

        this.stateExist = true;
        this.newRM = !this.activeMethodology ? true : false;
        !this.newRM ? this.refreshSelected(this.activeMethodology) : '';
        this.selectedRmName = this.activeMeth?.name;
        this.selectedRmDescription = this.activeMeth?.description;
        this.data = res?.data;
      },
      error: (error) => {},
      complete: () => {},
    });
  }

  refreshSelected(rm: RiskMethodology) {
    this.activeMeth = cloneDeep(rm);
    this.impactList = this.activeMeth.impactValues;
    // this.prepareFactors();
    this.likelihoodList = this.activeMeth.likelihoodValues;
    this.thresholdList = this.activeMeth.thresholdValues;
    this.stateExist = true;
    this.calculateMinMax();
  }

  calculateMinMax() {
    this.maxThreshold = maxBy(this.impactList, 'value').value * maxBy(this.likelihoodList, 'value').value;
    this.minThreshold = minBy(this.impactList, 'value').value * minBy(this.likelihoodList, 'value').value;

    this.acceptableRiskValue =
      this.activeMeth?.acceptableRisk > 0
        ? this.activeMeth?.acceptableRisk
        : (this.maxThreshold + this.minThreshold) / 2;
    this.options = {
      floor: this.minThreshold,
      ceil: this.maxThreshold,
    };
  }

  onEdit(event: any) {
    let config: IConfirmationConfig = new IConfirmationConfig();

    config.position = IConfirmationPosition.top;

    config.header = `Editing Risk Methodology`;
    config.message = 'Changing Risk Methodology may lead to data inconsistency';

    this.appDialogService.confirm(
      {
        accept: () => {
          this.riskMethodologyDataService.navigateToEditPage();
        },
      },
      config
    );
  }

  onViewModeChange(viewMode: IViewMode) {
    if (viewMode == 'edit') this.onEdit({});
    else {
      this.router.navigateByUrl(this.getPageViewModeLink(viewMode, this.itemId));
    }
  }
  getPageViewModeLink(viewMode: IViewMode, id = null) {
    return `/${this.routePrefix}${toKebabCase(this.moduleKeyword)}${this.routeMiddlefix}/${viewMode}`;
  }
  openModal() {
    this.appDialogService.showDialog(SummaryPopupComponent, 'Risk Matrix', (e) => {}, {
      data: {
        maxThreshold: this.maxThreshold,
        methodology: this.activeMethodology,
        showExpandButton: false,
      },
      width: '60%',
      height: '60%',
    });
  }
}
