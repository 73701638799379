<app-base-page-item
  [data]="data"
  [recordStatusActions]="recordStatusActions"
  [extendLockTimeAction]="extendLockTimeAction"
  [showLocked]="showLocked"
  [showCommentsButton]="editMode"
  [showStateMachineAuditTrail]="showStateMachineAuditTrail"
  [showHistoryButton]="editMode"
  [showAuditButton]="editMode"
  [showAddCommentButton]="editMode"
  [showShareButton]="editMode"
  [breadCrumb]="breadCrumb"
  [sourceCode]="itemId"
  [hasEditChanges]="hasEditChanges$ | async"
  [fromType]="fromType"
  [showRelations]="editMode"
  [showViewSwitch]="editMode"
  (onViewModeChange)="onViewModeChange($event)"
  [moduleKeyword]="moduleKeyword"
>
  <ng-container page-header>
    <div class="flex flex-wrap gap-3">
      <h4 class="mb-0">{{ editMode ? (viewModeService.viewMode | titlecase) : 'Add' }} Methodology</h4>
      @if (editMode) {
        <div class="align-self-center">
          <app-badge-item
            [status]="data?.status"
            [text]="data?.status"
            (click)="showStateMachine()"
            class="cursor-pointer"
          ></app-badge-item>
        </div>
      }
    </div>
  </ng-container>
  <ng-container page-actions>
    @if (viewMode == 'edit' && showRuleHandler) {
      <app-flat-rule-handler
        [actionMap]="actionsMap"
        [config]="{ requestService: requestService, itemId: itemId }"
      ></app-flat-rule-handler>
    }
  </ng-container>
  <app-risk-methodology-form
    (formChanges)="formChanges($event)"
    (formSubmit)="onSubmit($event)"
    (formSubmitSave)="onSubmit($event, 'PAGE')"
    (formSubmitInPlace)="onSubmit($event, 'NONE')"
    (cancelEvent)="onCancel($event)"
    [resetForm]="resetFormTrigger"
    (valueEditChanged)="valueEditChanged($event)"
    [formData]="data"
  ></app-risk-methodology-form>
</app-base-page-item>
