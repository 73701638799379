@if (!showSummaryOnly) {
  <app-base-page-item
    [data]="data"
    [recordStatusActions]="recordStatusActions"
    [extendLockTimeAction]="extendLockTimeAction"
    [showLocked]="showLocked"
    [breadCrumb]="breadCrumb"
    [sourceCode]="itemId"
    [fromType]="fromType"
    [showRelations]="editMode"
    [showShareButton]="editMode"
    [showViewSwitch]="false"
    [showRecordStatus]="false"
    [showCommentsButton]="false"
    (onViewModeChange)="onViewModeChange($event)"
    [moduleKeyword]="moduleKeyword"
    [hasEditChanges]="false"
    [hideTagsInput]="true"
  >
    <ng-container page-header>
      @if (!showSummaryOnly) {
        <h4 class="mb-0">
          Risk Matrix
          @if (activeMethodology?.code) {
            <app-code-navigation
              [staticData]="activeMethodology"
              [data]="activeMethodology?.code"
            ></app-code-navigation>
          }
          <i class="pi pi-external-link ml-2 cursor-pointer" style="font-size: 1rem" (click)="openModal()"></i>
        </h4>
      }
      <!-- <p *ngIf="!showSummaryOnly" class="text-muted font-14">
      Risk rating = Impact x Probability
    </p> -->
    </ng-container>
    <app-risk-methodology-summary
      [methodology]="activeMethodology"
      [maxThreshold]="maxThreshold"
    ></app-risk-methodology-summary>
    <!-- <div class="mx-2">
  <app-button [action]="submitButtonAction"></app-button>
</div> -->
  </app-base-page-item>
}

@if (showSummaryOnly) {
  <app-risk-methodology-summary
    [methodology]="activeMethodology"
    [maxThreshold]="maxThreshold"
    [showSummaryOnly]="showSummaryOnly"
  ></app-risk-methodology-summary>
}
