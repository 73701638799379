import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  BaseTablePage,
  ButtonColor,
  makePlural,
  ModuleKeywordRootPath,
  ModuleKeywords,
  ModuleRoutePrefix,
  PermissionActions,
  RiskMethodologyDto,
  routeToLocaleCase,
  toCamelCase,
  toKebabCase,
} from '@shared/classes';
import { AppDialogService, ExportDataService } from '@shared/services';
import { RiskMethodologyMappingService } from '@shared/services/mappings/risk/risk-methodology-mapping.service';
import { AppInjector } from 'app/app-injector';
import { RiskMethodologyDataService } from '../../services/data/risk-methodology-data.service';

@Component({
  selector: 'app-risk-methodology-list',
  templateUrl: './risk-methodology-list.component.html',
  styleUrl: './risk-methodology-list.component.scss',
})
export class RiskMethodologyListComponent extends BaseTablePage<RiskMethodologyDto> implements OnInit {
  constructor(
    requestService: RiskMethodologyDataService,
    exportService: ExportDataService,
    appDialogService: AppDialogService,
    router: Router,
    public localeService: LocaleService
  ) {
    super(
      requestService,
      exportService,
      appDialogService,
      router,
      {
        moduleKeyword: ModuleKeywords.RiskMethodology,
        routePrefix: ModuleRoutePrefix.Risk,
      },
      localeService
    );

    this.SetPageValues({
      breadCrumb: {
        items: [
          {
            label: this.localeService.translate(
              `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(ModuleKeywordRootPath[this.moduleKeyword])}`
            ),
            routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
          },
          {
            label: this.localeService.translate(
              `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${makePlural(toCamelCase(this.moduleKeyword))}`
            ),
            routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
          },
        ],
      },
      pageActions: [
        {
          label:
            this.localeService.translate('general.actions.add') +
            ' ' +
            this.localeService.translate(
              `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${toCamelCase(this.moduleKeyword)}`
            ),
          id: 1,
          color: ButtonColor.Primary,
          icon: 'pi pi-plus',
          iconPos: 'left',
          command: this.addNewItem.bind(this),
          permission: `${PermissionActions.Create}${this.moduleKeyword}`,
        },
      ],
    });
  }

  ngOnInit(): void {}

  setCols() {
    const mappingService = AppInjector.get(RiskMethodologyMappingService);
    this.cols = [...mappingService.tableFields].map((x) => {
      return {
        ...x,
        name: this.localeService.translate(
          `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.fields.${x.key}.label`,
          x.name
        ),
      };
    });
  }
}
